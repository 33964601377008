<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="120px">

    <el-form-item :label="$t('goods.Publish.440664-0')" prop="imageUrl">
      <div style="display: flex">
        <div
            v-for="(item, index) in form.imageUrl"
            :key="index"
            class="shoplistimg"
            draggable="true"
            @dragstart="dragStart($event, index, item)"
            @drop="drop($event, index, item)"
        >
          <i class="el-icon-delete-solid delicon" @click="delimg(index)"></i>
          <el-image
              fit="cover"
              style="width: 148px; height: 148px; margin-right: 4px"
              :src="item"
          ></el-image>
        </div>

        <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            name="files"
            list-type="picture-card"
            :before-upload="beforeupload"
            :on-success="onUploadSuccess"
            :on-error="onUploadError"
            :on-exceed="whenUploadOverLimitNum"
            :multiple="false"
            :headers="fileHeaders"
            :show-file-list="false"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>

      <div class="tips" style="margin-top: 5px">
        {{ $t('goods.Publish.440664-1') }}
      </div>
    </el-form-item>
    <el-form-item :label="$t('goods.Publish.440664-2')" prop="name">
      <el-input v-model="form.name" maxlength="30" :placeholder="$t('goods.Publish.440664-3')">
      </el-input>
      <span>{{ form.name.length }}/30</span>
    </el-form-item>


    <el-form-item :label="$t('goods.Publish.440664-46')" prop="name">
      <el-input v-model="form.nameEng" maxlength="30" :placeholder="$t('goods.Publish.440664-46')">
      </el-input>
      <span>{{ form.name.length }}/30</span>
    </el-form-item>


    <el-form-item :label="$t('goods.Publish.440664-4')" prop="productTypeId">
      <el-cascader
          :options="goodsClassify"
          v-model="form.productTypeId"
          clearable
      ></el-cascader>
    </el-form-item>


    <el-form-item :label="$t('goods.Publish.440664-5')" prop="isOne">
      <div>

        <el-radio v-model="form.isOne" :label="2">{{ $t('goods.Publish.440664-6') }}</el-radio>
      </div>
      <div class="tips" v-show="form.isOne == 1">
        {{ $t('goods.Publish.440664-7') }}
      </div>
    </el-form-item>


    <el-form-item :label="$t('goods.Publish.440664-8')" prop="goodsSpecFormat">
      <div class="sepc-box">
        <div>
          <el-button @click="onAddSpecBtn">{{ $t('goods.Publish.440664-9') }}</el-button>
        </div>
        <div
            class="f-fs-fs-c"
            v-for="(specItem, ind) in form.goodsSpecFormat"
            :key="ind"
        >
          <!-- 规格名 -->
          <div class="sepc-box-list f-sb-c">
            <el-input
                v-model="specItem.spec_name"
                :placeholder="$t('goods.Publish.440664-10')"
            ></el-input>
            <i class="el-icon-delete sepc-box-icon" @click="delsku(ind)"></i>
          </div>

          <!-- 规格值 -->
          <div
              class="sepc-box-list f-sb-c"
              v-for="(it, i) in specItem.value"
              :key="i"
          >
            <el-input
                v-model="it.spec_value_name"
                :placeholder="$t('goods.Publish.440664-11')"
            ></el-input>
            <i
                class="el-icon-minus sepc-box-icon f-c-c"
                @click="delval(ind, i)"
            ></i>
          </div>
          <el-button @click="onAddSpecValBtn(ind)" type="primary"
          >{{ $t('goods.Publish.440664-12') }}
          </el-button
          >
        </div>

        <div>
          <el-button @click="onCreateSpecsTab" type="primary"
          >{{ $t('goods.Publish.440664-13') }}
          </el-button
          >
        </div>

        <div class="specstab-box">
          <table
              class="specstab"
              :style="'width:' + tableTitle.length * 142 + 'px;'"
              cellspacing="0px"
          >
            <thead>
            <tr>
              <td v-for="(item, index) in tableTitle" :key="index">
                {{ item }}
              </td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in tableContent" :key="index">
              <!-- 这里的数据是合并行的 -->
              <td v-for="(item2, index2) in item" :key="index2">
                <span v-if="item2.type == $t('goods.Publish.440664-14')">{{ item2.value }}</span>
                <el-input
                    v-if="item2.type == $t('goods.Publish.440664-15')"
                    :maxlength="6"
                    v-model="item2.value"
                ></el-input>
                <el-input
                    v-if="item2.type == $t('goods.Publish.440664-16')"
                    :maxlength="6"
                    v-model="item2.value"
                ></el-input>

                <el-input
                    v-if="item2.type == $t('goods.Publish.440664-17')"
                    v-model="item2.value"
                    :validate-event="true"
                ></el-input>
              </td>
            </tr>
            </tbody>
          </table>
        </div>


      </div>
    </el-form-item>

  
    <el-form-item
        :label="$t('goods.Publish.440664-18')"
        prop="deliveryType"
        style="position: relative; z-index: 0"
    >
      <el-checkbox-group v-model="form.deliveryType">
        <el-checkbox label="2" name="deliveryType">{{ $t('goods.Publish.440664-19') }}</el-checkbox>
        <el-checkbox label="1" name="deliveryType">{{ $t('goods.Publish.440664-20') }}</el-checkbox>
        <el-checkbox label="3" name="deliveryType">{{ $t('goods.Publish.440664-21') }}</el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <el-form-item :label="$t('goods.Publish.440664-22')" prop="canBuy">
      <el-radio-group v-model="form.canBuy">
        <el-radio label="1">{{ $t('goods.Publish.440664-23') }}</el-radio>
        <el-radio label="0">{{ $t('goods.Publish.440664-24') }}</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item
        :label="$t('goods.Publish.440664-25')"
        prop="detail"
        style="position: relative; z-index: 0"
    >
      <BaseEditor
          ref="getHtml"
          :height="800"
          :html.sync="form.detail"
          :placeholder="$t('goods.Publish.440664-26')"
      />
    </el-form-item>


    <el-form-item style="position: relative; z-index: 0">
      <el-button type="primary" @click="onSubmit()">{{ $t('goods.Publish.440664-27') }}</el-button>
      <el-button @click="$refs.form.resetFields(onreset())">{{ $t('goods.Publish.440664-28') }}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
const languageType = localStorage.getItem('lang') || 'zh';
const languageList = {
  zh: {
    accept: 'zh-cn;q=0.5',
    country: 'China'
  },
  en: {
    accept: 'en-us;q=0.5',
    country: 'Other'
  },
  mn: {
    accept: 'mn;q=0.5',
    country: 'Mongolia'
  }
}
export default {
  data() {
    return {
      fileHeaders: {
        'env': process.env.NODE_ENV,
        'Accept-Language': languageList[languageType].accept,
        'Country': languageList[languageType].country,
        'Authorization': localStorage.getItem("token")
      },
      indexStart: null, //拖拽下标
      indexEnd: null, //拖拽下标

      productId: "", //需要编辑的商品id
      title: "", //规格名称
      list: [], //表中生成前的数据
      tableTitle: [], //表头
      tableContent: [], //生成后表中的数据

      editorHtml: "",
      editorText: "",
      quota: 0, //限购
      uploadUrl: this.$config.baseUrl + "/api/common/fileInfo/newUpload",
      fileList: [], //图片列表
      //商品分类
      goodsClassify: [],
      imgs: [],
      specs: {
        head: [this.$t('goods.Publish.440664-29'), this.$t('goods.Publish.440664-30'), this.$t('goods.Publish.440664-31'), this.$t('goods.Publish.440664-16'), this.$t('goods.Publish.440664-17')], //[this.$t('goods.Publish.440664-29'), this.$t('goods.Publish.440664-30'), this.$t('goods.Publish.440664-31'), this.$t('goods.Publish.440664-17')]
        body: ["color", "size", "price", "personal_price", "stock"], //["color", "size", "price", "stock"]
        list: [],
      },
      form: {
        deliveryType: [], //配送方式：1自提2物流3配送
        detail: "", //商品详情，富文本数据
        goodsSpecFormat: [], //规格详情
        imageUrl: [], //图片地址
        isOne: 2, //是否一号店商品：1是 2不是
        name: "", //商品名称
        productTypeId: [], //商品类型id
        repositoryList: [], //库存详情
        shopId: "", //店铺id
        // salesVirtual:0, //虚拟销量
        limitedQuantity: 0, //限购数量
        canBuy: "1",
        nameEng:''
      },
      rules: {
        deliveryType: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        // salesVirtual:[
        //   {
        //     required: true,
        //     message: this.$t('goods.Publish.440664-32'),
        //   },
        // ],
        detail: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        goodsSpecFormat: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        imageUrl: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        isOne: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-33'),
          },
        ],
        productTypeId: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        repositoryList: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        shopId: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-32'),
          },
        ],
        canBuy: [
          {
            required: true,
            message: this.$t('goods.Publish.440664-34'),
          },
        ],
      },
    };
  },
  created() {
    let that = this;
    // 编辑时
    if (this.$route.query.productId) {
      let productId = this.$route.query.productId;
      this.productId = this.$route.query.productId;
      // console.log("url参数", productId);
      this.getSpecInfoById(
          {
            productId: productId,
          },
          (data) => {
            // console.log(data);
            if (data.imageList.length > 0) {
              data.imageUrl = data.imageList.map((key) => {
                return key.imageUrl;
              });
            } else {
              data.imageUrl = data.imageUrl == "" ? [] : data.imageUrl.split(","); //商品图片
            }
            /** 限购功能
             *  if(data.limitedQuantity !== "null" && data.limitedQuantity && data.limitedQuantity!=="0"){
             console.log('----------------------------------------------------------------')
             that.quota = 1;
             // this.$set(that.form,quota,1)
             that.form.limitedQuantity = data.limitedQuantity
             }else{
             that.form.limitedQuantity = 0
             }
             *
             * */

            data.deliveryType = data.deliveryType.split(","); //配送方式

            // console.log("判断是否", data.deliveryType);
            //todo

            // if (data.deliveryType.indexOf("2") == -1) {
            //此处处理老数据 这个字段为null时，无法添加规格
            //   data.deliveryType.unshift("2");
            // }

            if (data.goodsSpecFormat instanceof Array == false) {
              data.goodsSpecFormat = [];
            }
            this.form = data;
            if (data.detail) {
              this.$refs.getHtml.setHtml(data.detail);
            }
            this.onCreateSpecsTab();
          }
      );
    }

    this.getGoodsClassify();

    // console.log("deliveryType", this.form.deliveryType);
  },
  methods: {
    /**
     *
     *  限购功能暂时关闭
     *     quotaChange(e) {
     this.form.limitedQuantity = "";
     },
     changeLimitedQuantity() {
     var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
     // 不符合正整数时
     if (!pattern.test(this.form.limitedQuantity)) {
     // input 框绑定的内容为空
     this.form.limitedQuantity = 0;
     }
     },
     *
     */

    //获取商品信息
    getSpecInfoById(e, fun) {
      this.$api.goods.getSpecInfoById(e).then((res) => {
        if (res.success) {
          fun(res.data);
          // console.log("this.form", this.form);
        } else {
          this.$message({
            message: res.message,
            type: "success",
          });
        }
        // console.log("商品详情编辑", res.data);
      });
    },

    //获取商品分类信息
    getGoodsClassify() {
      this.$api.goods.getClassify().then((res) => {
        this.goodsClassify = res.data.map((item) => {
          item.label = item.classify.name;
          item.value = item.classify.id;
          item.children.forEach((it) => {
            it.label = it.name;
            it.value = it.id;
            return it;
          });
          return item;
        });
      });
    },
    //删除当前轮播图片
    delimg(ind) {
      this.form.imageUrl.splice(ind, 1);
      // console.log(this.form.imageUrl, "this.form.imageUrl");
    },
    // 上传文件之前的钩子
    beforeupload(file) {
      if (this.form.imageUrl.length >= 5) {
        this.$message({
          message: this.$t('goods.Publish.440664-35'),
          type: "warning",
        });
        // console.log("上传文件之前的钩子");

        return false;
      } else {
        // console.log("file.type", file.type);
        const isJPG = file.type == "image/png" || file.type == "image/jpeg";
        // console.log(isJPG)
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error(this.$t('goods.Publish.440664-36'));
        }
        if (!isLt2M) {
          this.$message.error(this.$t('goods.Publish.440664-37'));
        }
        return isJPG && isLt2M;
      }
    },
    //当上传图片成功时
    onUploadSuccess(res, file, fileList) {
      if (this.form.imageUrl.length < 5) {
        this.form.imageUrl.push(res.data[0].fileUrl);
      } else {
        this.$message({
          message: this.$t('goods.Publish.440664-35'),
          type: "warning",
        });
      }
    },
    //当上传失败时
    onUploadError(err, file, fileList) {
      // console.log("上传图片失败：", err);
    },
    //生成规格表
    onCreateSpecsTab() {
      let specItems = this.form.goodsSpecFormat || [];

      this.list = []; //表中生成前的数据
      this.tableTitle = []; //表头
      this.tableContent = []; //生成后表中的数据

      // console.log("生成规格前", specItems);

      let ind = "";
      this.list = specItems.map((item) => {
        if (ind == "") {
          ind = 0;
        } else {
          ind++;
        }
        let alls = item.value.map((item1) => {
          return {
            spec_value_Id: "",
            value: item1.spec_value_name,
          };
        });

        return {
          sort: ind,
          new: false,
          title: item.spec_name,
          list: alls,
        };
      });

      //计算 笛卡尔积
      if (!this.list.length) return false;
      let flag = false;
      flag = this.list.some((item) => {
        return item.list.length;
      });
      if (!flag) return false;
      this.handleTableHead();
      this.handleTableData();
    },

    // 生成表头
    handleTableHead() {
      this.tableTitle = [];
      this.list.forEach((item) => {
        if (item.list.length) {
          this.tableTitle.push(item.title);
        }
      });
      this.tableTitle.push(this.$t('goods.Publish.440664-15'), this.$t('goods.Publish.440664-16'), this.$t('goods.Publish.440664-17'));
    },

    // 处理表格中的数据
    handleTableData(num) {
      const listData = this.list.filter((item) => item.list.length); //去掉没有值的规格
      if (this.tableContent.length) {
        // 表格中数据不是第一次添加时
        let tempList = []; //用于存放新加的规格和值
        let tempContentList = this.tableContent.length; //记录上一次数据的总长度
        listData.forEach((item, index) => {
          item.list.forEach((item2, index2) => {
            if (item2.new) {
              //新添加的值
              let flag = false; // flag用于判断新值有没有添加进tempList
              let count = -1;
              flag = tempList.some((sitem, sindex) => {
                count++;
                return sitem.title == item.title;
              });
              if (flag) {
                //如果添加过则直接在里面的list追加
                tempList[count].list.push(item2);
              } else {
                //没添加过就添加一个新的对象
                tempList.push({
                  title: item.title,
                  list: [item2],
                  new: item.new,
                  sort: item.sort,
                });
              }
            }
          });
        });
        tempList.forEach((item) => {
          const list = [];
          // list代表所有新值的规格和其他规格匹配的数据
          if (item.new && item.list.length == 1) {
            // 如果新加的规格的值只有一个，那么直接在每一列加新值
            for (let i = 0; i < tempContentList; i++) {
              this.tableContent[i].splice(item.sort, 0, {
                type: this.$t('goods.Publish.440664-14'),
                value: item.list[0].value,
                sort: item.sort,
                key: item.title,
              });
            }
          } else {
            // 如果新加的规格的值大于一个，那么先在每一列插入新值
            // 然后再拿其他值互相匹配
            if (item.new && item.list.length > 1) {
              for (let i = 0; i < tempContentList; i++) {
                this.tableContent[i].splice(item.sort, 0, {
                  type: this.$t('goods.Publish.440664-14'),
                  value: item.list[0].value,
                  sort: item.sort,
                  key: item.title,
                });
              }
              // 第一个值已经加进去，所以要删掉
              item.list.splice(0, 1);
            }
            // 先把新加的值加进去
            list.push(item);
            listData.forEach((listItem) => {
              listItem.list.forEach((listItem2) => {
                item.list.forEach((item2) => {
                  if (listItem2.value == item2.value) {
                    // 上面的新值已经加进去所以要在原来的listData中做标记
                    // 避免下面出现重复的组合
                    listItem2.disabled = true;
                  }
                });
              });
            });

            listData.forEach((listItem) => {
              if (listItem.title !== item.title) {
                // 深拷贝避免影响原来的数据
                let tempListItem = JSON.parse(JSON.stringify(listItem));
                for (let count = 0; count < tempListItem.list.length; count++) {
                  if (tempListItem.list[count].disabled) {
                    //已经加过的值删掉
                    tempListItem.list.splice(count, 1);
                    count--;
                  }
                }
                list.push(tempListItem); // 加进list组合成新的数据
              }
            });
            list.sort((n1, n2) => n1.sort - n2.sort); //排序 避免新加的表中数据和表头对应不上
            this.transformData(list);
          }
        });
        // 生成后把所有的值初始化 用于下次生成判断新值
        this.list.forEach((item) => {
          item.new = false;
          item.list.forEach((item2) => {
            item2.new = false;
            item2.disabled = false;
          });
        });
        return false;
      }
      this.transformData(listData);
    },

    transformData(list) {
      const arr = [];
      // 这里改造一下数据
      list.forEach((item) => {
        const tempList = [];
        item.list.forEach((item2) => {
          let obj = {
            type: this.$t('goods.Publish.440664-14'),
            value: item2.value,
            sort: item.sort,
            key: item.title,
          };
          tempList.push(obj);
        });
        arr.push(tempList);
      });
      // 再改造成真正的表中数据类型
      const data = this.cartesianProductOf(...arr);
      // 这里给表中每行加一些写死的值，如输入框 单选按钮等
      data.forEach((item) => {
        // 判断是否是编辑时，是则插入价格
        // console.log('当前的==',this.form.repositoryList,'----',item)
        if (this.form.repositoryList.length > 0) {
          let types = ""; //用于规格名组合
          item.forEach((item1) => {
            if (item1.type == this.$t('goods.Publish.440664-14')) {
              if (types == "") {
                types = item1.value;
              } else {
                types = types + ";" + item1.value;
              }
            }
          });

          let val = true; //用于判断新增字段规格
          item.forEach((item1) => {
            this.form.repositoryList.forEach((key) => {
              if (key.productName == types && val) {
                // console.log(item1, this.$t('goods.Publish.440664-17'));
                val = false;

                item.push({
                  type: this.$t('goods.Publish.440664-15'),
                  value: key.price,
                  rowspan: 1,
                });
                item.push({
                  type: this.$t('goods.Publish.440664-16'),
                  value: key.personal_price,
                  rowspan: 1,
                });
                item.push({
                  type: this.$t('goods.Publish.440664-17'),
                  value: key.count,
                  rowspan: 1,
                });
                item.push({
                  type: "ID",
                  value: key.repositoryId,
                  rowspan: 1,
                });
              }
            });
          });
          if (val) {
            item.push({
              type: this.$t('goods.Publish.440664-15'),
              value: "",
              rowspan: 1,
            });
            item.push({
              type: this.$t('goods.Publish.440664-16'),
              value: "",
              rowspan: 1,
            });
            item.push({
              type: this.$t('goods.Publish.440664-17'),
              value: "",
              rowspan: 1,
            });
          }
        } else {
          item.push({
            type: this.$t('goods.Publish.440664-15'),
            value: "",
            rowspan: 1,
          });
          item.push({
            type: this.$t('goods.Publish.440664-16'),
            value: "",
            rowspan: 1,
          });
          item.push({
            type: this.$t('goods.Publish.440664-17'),
            value: "",
            rowspan: 1,
          });
        }
      });
      //与原来的数据合并
      this.tableContent.push(...data);
      // console.log("数据合并后", this.tableContent);
    },
    // 笛卡尔积
    cartesianProductOf() {
      return Array.prototype.reduce.call(
          arguments,
          function (a, b) {
            var ret = [];
            a.forEach(function (a) {
              b.forEach(function (b) {
                ret.push(a.concat(b));
                // ret.push([...a, ...b]);
              });
            });
            return ret;
          },
          [[]]
      );
    },

    //

    //当上传图片的数量超出限制数量时
    whenUploadOverLimitNum() {
      this.$message.warning(this.$t('goods.Publish.440664-38'));
    },
    //当点击新增规格按钮时
    onAddSpecBtn() {
      if (this.form.goodsSpecFormat.length < 2) {
        this.form.goodsSpecFormat.push({
          spec_name: "",
          spec_Id: "",
          value: [
            {
              spec_value_Id: "",
              spec_value_name: "",
            },
          ],
        });
      } else {
        this.$message({
          message: this.$t('goods.Publish.440664-39'),
          type: "warning",
        });
      }
    },
    //当点击新增规格值按钮时
    onAddSpecValBtn(ind) {
      this.form.goodsSpecFormat[ind].value.push({
        spec_value_Id: "",
        spec_value_name: "",
      });
    },

    //删除当前规格值  js刪除数组
    delval(ind, i) {
      let list = this.form.goodsSpecFormat;
      list[ind].value.splice(i, 1);
      this.form.goodsSpecFormat = list;

      // console.log(ind, i, "304", list);
    },

    //删除当前规格
    delsku(ind) {
      let list = this.form.goodsSpecFormat;
      list.splice(ind, 1);
      this.form.goodsSpecFormat = list;
    },

    onWaysChange(val) {
      // console.log("选中", val, "==", this.form.deliveryType);
    },

    //重设
    onreset() {
      // console.log("重设++++++++++++");
      this.list = []; //表中生成前的数据
      this.tableTitle = []; //表头
      this.tableContent = []; //生成后表中的数据
    },

    isPriceNumber(_keyword) {
      if (
          _keyword == "0" ||
          _keyword == "0." ||
          _keyword == "0.0" ||
          _keyword == "0.00"
      ) {
        _keyword = "0";
        return true;
      } else {
        var index = _keyword.indexOf("0");
        var length = _keyword.length;
        if (index == 0 && length > 1) {
          /*0开头的数字串*/
          var reg = /^[0]{1}[.]{1}[0-9]{1,2}$/;
          if (!reg.test(_keyword)) {
            return false;
          } else {
            return true;
          }
        } else {
          /*非0开头的数字*/
          var reg = /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/;
          if (!reg.test(_keyword)) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }
    },

    //发布
    onSubmit() {
      //不要删除此处注释
      //传参数的格式
      // let params={
      //   deliveryType: "1,3,2"
      //   detail: "<p>连连看</p>"
      //   goodsSpecFormat: "[{'spec_name':this.$t('goods.Publish.440664-30'),'spec_Id':2278,'type':'select','value':[{'spec_value_Id':10807,'spec_value_name':'1'},{'spec_value_Id':10808,'spec_value_name':'2'}]}]"
      //   imageUrl: "https://image.soole.com.cn/c292f03d-b3dd-43d3-82b9-9f1bd3e9477a,https://image.soole.com.cn/c292f03d-b3dd-43d3-82b9-9f1bd3e9477a"
      //   isOne: 2
      //   name: "大师傅"
      //   productTypeId: 643
      //   repository List: "[{'productName':'1','price':'1.00','count':1,'goodsSpecFormat':[{'spec_value_name':'1','spec_value_Id':10807}]},{'productName':'2','price':'1.00','count':1,'goodsSpecFormat':[{'spec_value_name':'2','spec_value_Id':10808}]}]"
      //   shopId: 1252818
      // }

      // 生成 goodsSpecFormat
      // console.log("this.form", this.form);
      let specItems = this.form.goodsSpecFormat || [];

      let lock = "";
      // console.log("specItems", specItems);

      let tableContent = this.tableContent;

      // console.log("需要发布的信息", tableContent);

      // 转化组合数据  repository List
      let listarr = [];
      tableContent.forEach((item, index) => {
        let all = {
          productName: "",
          price: "",
          personal_price: "",
          count: "",
          repositoryId: "",
          goodsSpecFormat: [],
        };
        item.forEach((item1, index1) => {
          if (item1.type == this.$t('goods.Publish.440664-14')) {
            if (item1.value == "") {
              lock = this.$t('goods.Publish.440664-40');
            }

            all.goodsSpecFormat.push({
              spec_value_name: item1.value,
              spec_value_Id: "",
            });
            all.productName = all.productName + item1.value + ";";
          } else if (item1.type == this.$t('goods.Publish.440664-15')) {
            item1.value = String(item1.value);
            if (this.isPriceNumber(item1.value) == false) {
              lock = this.$t('goods.Publish.440664-41');
            }
            all.price = item1.value;
          } else if (item1.type == this.$t('goods.Publish.440664-17')) {
            const result = /^\d+$/.test(item1.value);
            if (!result) {
              lock = this.$t('goods.Publish.440664-42');
            }
            all.count = item1.value;
          } else if (item1.type == this.$t('goods.Publish.440664-16')) {
            item1.value = String(item1.value);
            if (this.isPriceNumber(item1.value) == false) {
              lock = this.$t('goods.Publish.440664-43');
            }
            all.personal_price = item1.value;
          } else if (item1.type == "ID") {
            all.repositoryId = item1.value;
          }
        });

        all.productName = all.productName.substring(
            0,
            all.productName.length - 1
        );
        listarr.push(all);
      });

      if (!listarr.length) {
        lock = this.$t('goods.Publish.440664-44');
      }
      let params = {
        ...this.form,
        shopId: this.$store.state.user.shopId,
      };

      params.deliveryType = params.deliveryType.toString();
      params.imageUrl = params.imageUrl.toString();
      params.goodsSpecFormat = JSON.stringify(params.goodsSpecFormat);

      if (this.productId == "") {
        params.productTypeId = params.productTypeId[1];
      } else if (typeof params.productTypeId == "object") {
        params.productTypeId = params.productTypeId[1];
      }
      params.repositoryList = JSON.stringify(listarr);

      //获取富文本
      params.detail = this.$refs.getHtml.getHtml();
      this.form.detail = params.detail;

      if (lock != "") {
        this.$message({
          message: lock,
          type: "warning",
        });
        return;
      }

      // console.log("需要提交的数据", params);
      /**
       *
       * 限购商品暂时关闭
       *  // 是否限购
       if (this.quota) {
       if (!params.limitedQuantity) {
       this.$message.error("请输入限购数量");
       return
       }
       } else {
       params.limitedQuantity = 0;
       }
       * **/
      // 传0 默认不限购
      params.limitedQuantity = 0;
      params.salesVirtual = 9999;
      // return
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.productId) {
            //编辑
            let productId = this.productId;

            this.$api.goods

                .updateProductRelease({
                  ...params,
                  productId: productId,
                })
                .then((res) => {
                  this.$message({
                    message: res.message,
                    type: "success",
                  });
                  this.$router.push({path: "/manage/goods/list", query: {}});
                  // console.log("发布编辑商品返回", res);
                });
          } else {
            //发布
            this.$api.goods
                .publish(params)
                .then((res) => {
                  this.$message({
                    message: res.message,
                    type: "success",
                  });
                  this.$router.push({path: "/manage/goods/list", query: {}});
                  // console.log("发布商品返回", res);
                })
                .catch((err) => {
                  console.log(err);
                });
          }
        } else {
          this.$message.warning(this.$t('goods.Publish.440664-45'));
        }
      });
    },

    //拖拽图片
    dragStart(e, index, filed) {
      this.indexStart = index;
    },
    drop(e, index, filed) {
      this.indexEnd = index;
      let x = this.indexStart + 1;
      let y = index + 1;
      this.form.imageUrl.splice(
          x - 1,
          1,
          ...this.form.imageUrl.splice(y - 1, 1, this.form.imageUrl[x - 1])
      );
    },
  },
  watch: {
    "form.isOne": {
      handler: function (newVal, oldVal) {
        // console.log(this.$refs.getHtml.getHtml());
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input,
.el-cascader {
  width: 400px;
}

.upload-imgs {
  color: $color-text-light;
  width: 60px;
  height: 60px;
  border: 1px solid $color-border-main;
  border-radius: $border-radius-main;
  font-size: 30px;

}

.tips {
  @include tips-text;
}

.sepc-box {
  .el-button {
    margin: 0;
    margin-bottom: 10px;
  }

  .sepc-box-list {
    margin-bottom: 10px;
  }

  .sepc-box-icon {
    cursor: pointer;
    width: 36px;
    height: 36px;
    margin-left: 10px;
    border-radius: 50%;
    font-size: 20px;
    background-color: $color-theme;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
}

.shoplistimg {
  position: relative;
}

.delicon {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f35555;
  position: absolute;
  padding: 2px;
  z-index: 9;
  right: 4px;
  top: 0px;
  font-size: 22px;
}

//替换
.replaceicon {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f35555;
  position: absolute;
  padding: 2px;
  z-index: 9;
  right: 40px;
  top: 0px;
  font-size: 22px;
}

.specstab-box {
  overflow: auto;
  width: 100%;
}

.specstab {
  width: auto;
}

.specstab thead {
  background-color: #ebeef5;
}

.specstab .el-input {
  width: 100%;
}

.specstab td {
  text-align: center;
  width: 140px;
}

.specstab tbody td {
  border: 1px solid #f1f1f1;
}

.specstab tbody td .el-input__inner {
  border: none;
}
</style>
